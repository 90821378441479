import React from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import { graphql } from "gatsby"
import Content, { HTMLContent } from "../components/Content"
import Layout from "../components/Layout"

const LegalPageTemplate = ({ content, contentComponent, title, helmet }) => {
  const PostContent = contentComponent || Content

  return (
    <React.Fragment>
      {helmet || ""}
      <section>
        <div className="container">
          <h1>{title}</h1>
          <PostContent content={content} />
        </div>
      </section>
    </React.Fragment>
  )
}

LegalPageTemplate.propTypes = {
  content: PropTypes.string.isRequired,
  contentComponent: PropTypes.func,
  helmet: PropTypes.object,
  title: PropTypes.string,
}

const LegalPage = ({ data }) => {
  const { markdownRemark: post } = data

  return (
    <Layout>
      <LegalPageTemplate
        content={post.html}
        contentComponent={HTMLContent}
        title={post.frontmatter.title}
        helmet={
          <Helmet titleTemplate="%s | Dreamjot">
            <title>{`${post.frontmatter.title}`}</title>
            <meta
              name="description"
              content={`${post.frontmatter.description}`}
            />
            <meta name="robots" content="noindex" />
          </Helmet>
        }
      />
    </Layout>
  )
}

LegalPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }),
}

export default LegalPage

export const pageQuery = graphql`
  query LegalPageByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        title
        description
      }
    }
  }
`
